.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: black;
}

.App-link {
  font-size: 75%;
}
